import { pageBaseStatus } from "../pageBaseStatus";
import { actionNameCreator } from "../state-helpers/actionTypeNameCreator";
export const iniState = {
    status: pageBaseStatus,
    dm_truongs: [],
    dm_truong_id: localStorage.getItem("header_dm_truong_id") ? parseInt(localStorage.getItem("header_dm_truong_id")) : 0
}
export const truongActionTypes = {
    LOAD: actionNameCreator.TRUONG("LOAD"),
    CHANGE_SELECTED_ID: "CHANGE_SELECTED_ID",
}
export const truongReducer = (state = iniState, action) => {
    switch (action.type) {
        case truongActionTypes.LOAD.START:
            return {
                ...state,
                status: pageBaseStatus.is_loading,
                dm_truongs: undefined
            }
        case truongActionTypes.LOAD.SUCESS:
            return {
                ...state,
                status: pageBaseStatus.is_completed,
                dm_truongs: action.payload
            }
        case truongActionTypes.LOAD.ERROR:
            return {
                ...state,
                status: pageBaseStatus.is_completed,
                dm_truongs: []
            }
        case truongActionTypes.CHANGE_SELECTED_ID:
            // console.log(action.payload);
            localStorage.setItem("header_dm_truong_id", action.payload)
            return {
                ...state,
                dm_truong_id: action.payload
            };
        default:
            return state;
    }
}
