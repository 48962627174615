import { pageBaseStatus } from "../pageBaseStatus";
import { actionNameCreator } from "../state-helpers/actionTypeNameCreator";
export const iniState = {
    status: pageBaseStatus.is_not_initial,
    dm_cosos: [],
    dm_coso_id: localStorage.getItem("header_dm_coso_id") ? parseInt(localStorage.getItem("header_dm_coso_id")) : 0
}
export const coSoActionTypes = {
    LOAD: actionNameCreator.COSO("LOAD"),
    CHANGE_SELECTED_ID: "COSO_CHANGE_SELECTED_ID",
}
export const coSoReducer = (state = iniState, action) => {
    switch (action.type) {
        case coSoActionTypes.LOAD.START:
            return {
                ...state,
                status: pageBaseStatus.is_loading,
                dm_cosos: []
            }
        case coSoActionTypes.LOAD.SUCESS:
            return {
                ...state,
                status: pageBaseStatus.is_completed,
                dm_cosos: action.payload
            }
        case coSoActionTypes.LOAD.ERROR:
            return {
                ...state,
                status: pageBaseStatus.is_completed,
                dm_cosos: []
            }
        case coSoActionTypes.CHANGE_SELECTED_ID:
            // console.log(action.payload);
            localStorage.setItem("header_dm_coso_id", action.payload)
            return {
                ...state,
                dm_coso_id: action.payload
            };
        default:
            return state;
    }
}
