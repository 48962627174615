
export const actionNameCreator = {
    AUTH: (actionName) => {
        return actionNameCreatorStatus(`AUTH_${actionName}`)
    },
    TRUONG: (actionName) => {
        return actionNameCreatorStatus(`TRUONG_${actionName}`)
    },
    COSO: (actionName) => {
        return actionNameCreatorStatus(`COSO_${actionName}`)
    },
    NAMHOC: (actionName) => {
        return actionNameCreatorStatus(`NAMHOC_${actionName}`)
    }
}
const actionNameCreatorStatus = (actionName) => {
    return {
        START: actionName.toUpperCase() + "_START",
        SUCESS: actionName.toUpperCase() + "_SUCCESS",
        ERROR: actionName.toUpperCase() + "_ERROR"
    };
}